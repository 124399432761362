import React from 'react'
import { graphql } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import * as styles from './team-inner.module.scss'
import { tr } from 'js/translations.js'
import { useLocalization } from 'gatsby-theme-i18n'

import Banner from 'components/Banner'
import BgLink from 'components/BgLink'
import Breadcrumb from 'components/Breadcrumb'
import IntroText from 'components/IntroText'
import Inview from 'components/Inview'
import Layout from 'components/Layout'
import Seo from 'components/Seo'

const Page = ({ data }) => {
  const { locale } = useLocalization()
  const member = data[`member_${locale}`]
  const page = data[`page_${locale}`].nodes[0]

  const breadcrumb = [
    {
      text: tr('HOME', locale),
      link: '/',
    },
    {
      text: page.title,
      link: '/team/',
    },
    {
      text: member.name,
    },
  ]
  const bio = renderRichText(member.bio)
  const introText = renderRichText(page.introText)

  const seoTitle = `${page.title} - ${member.name}`
  // const seoDescription = member.bio.json.content[0].content[0].value

  return (
    <Layout>
      <div>
        <Seo
          pageTitle={seoTitle}
          // description={seoDescription}
        />
        <Banner image={page.banner} />
        <Breadcrumb items={breadcrumb} />

        <div className='wrapper pad-sides'>
          <div className='inner'>
            <IntroText content={introText} />
            <div className={styles.member}>
              <Inview>
                <div className='fade-in-up '>
                  <div className={styles.text}>
                    <h2>{member.name}</h2>
                    <h4>{member.jobTitle}</h4>
                    <div className={styles.line}></div>
                    <div className={styles.bio}>{bio}</div>
                  </div>
                  <BgLink to='/team/' text={`« ${tr('BACK_TO_ALL', locale)}`} />
                </div>
              </Inview>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query TeamMemberBySlug($slug: String!) {
    member_en: contentfulTeamMember(
      slug: { eq: $slug }
      node_locale: { eq: "en-US" }
    ) {
      name
      jobTitle
      bio {
        raw
      }
    }
    page_en: allContentfulTeamPage(filter: { node_locale: { eq: "en-US" } }) {
      nodes {
        title
        banner {
          ...GatsbyImage
        }
        introText {
          raw
        }
      }
    }
    member_zh: contentfulTeamMember(
      slug: { eq: $slug }
      node_locale: { eq: "zh-CN" }
    ) {
      name
      jobTitle
      bio {
        raw
      }
    }
    page_zh: allContentfulTeamPage(filter: { node_locale: { eq: "zh-CN" } }) {
      nodes {
        title
        banner {
          ...GatsbyImage
        }
        introText {
          raw
        }
      }
    }
  }
`
